import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#448aff'
      }
    }
  }
};
export default new Vuetify(vuetifyOptions);
